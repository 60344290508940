// src/data.js

export const projects = [
    {
      title: "Gems of War",
      subtitle: "Unity, Node.js, MongoDB",
      description:
        "Gems of War was first released across PC and Mobile in 2015 and is still going strong in 2024, also available on next-gen consoles. I lead the initial release as the sole engineer and continued in a technical leadership role throughout.",
      image: "./img/projects/gemsofwar.jpg",
      link: "https://gemsofwar.com",
    },
    {
      title: "Puzzle Quest 3",
      subtitle: "Unity, C#, .NET, PostgreSQL",
      description:
        "The 3rd installment of the popular series. Puzzle Quest 3 took Match 3 RPGs to a new level of technical excellence. I provided technical leadership as well as being involved in the design and production.",
      image: "./img/projects/puzzlequest3.jpg",
      link: "https://puzzlequest3.com",
    },
    {
      title: "Gems of War Help Center",
      subtitle: "Zendesk, HTML, CSS, JavaScript",
      description:
        "The Gems of War Help Center, hosted in Zendesk, provides key information about the game. I worked with requests from the Customer Experience team to heavily customise the theme and ensure that the help center could be accessed from web views within the app",
      image: "./img/projects/gemsofwar-helpcenter-frame.jpg",
      link: "https://gemsofwar.zendesk.com",
    },
    {
        title: "Gems of War Website",
        subtitle: "Wordpress, PHP, MySQL",
        description:
          "The website that existed as the blog and key source of information for the Gems of War community. It also helped market and grow the game. I developed it with Wordpress, heavily customising the theme and ensuring that suitable plugins were used.",
          image: "./img/projects/gemsofwar-website-frame.jpg",
        link: "https://gemsofwar.com",
    },      
    {
        title: "Infinity Plus Two Website",
        subtitle: "Wordpress, PHP, MySQL",
        description:
          "This was a single page website developed with Wordpress. It showcases the games that the company made as well as including BambooHR integration, to ensure that any open job opportunities would automatically be listed.",
          image: "./img/projects/infinityplustwo-website-frame.jpg",
        link: "https://infinityplustwo.net",
    },    
    {
        title: "This Portfolio",
        subtitle: "React.js",
        description:
          "This portfolio you are currently browsing was developed with React.js. It currently serves to show my past work and skills, but may be expanded on in future.",
          image: "./img/projects/portfolio-frame.jpg",
        link: "",
    },          
  ];


  export const testimonials = [
    {
      name: "Testimonial",
      company: "React Reserve",
      quote:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
      image: "./project-1.gif",
    },
  ];

  export const skills = [
    "Team management and leadership",
    "Agile/Certified ScrumMaster",
    "Mobile Development",
    "Web Development",
    "Performance Optimisation",
    "Stakeholder Management",
    "UI Design",
    "Jira Administration",
     "Quality Assurance",
    "Version Control",
    "Mentoring",
    "AWS and Cloud Services Administration",
     "Unity",
    "C#",
    "Javascript",
    "HTML",
    "CSS",
    "TypeScript",
    "Node.js",
    "SQL",
    "MongoDB",
    "Git",
    
    ];