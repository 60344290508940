// src/components/Contact.js

import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export default function Contact() {

  const validateName = (name) => {
    // todo
  }

  const validateEmail = (name) => {
    // todo
  }
  
  const validateMessage = (name) => {
    // todo
  }  

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_oztgb8z', 'template_hjvzt8v', form.current, {
        publicKey: 'IpV7HuxwxUFeGkNI9',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          alert("Message sent!");
        },
        (error) => {
          console.log('FAILED...', error);
        },
      );
  };

  return (
    <section id="contact" className="relative">
      <div className="container px-5 py-10 mx-auto flex sm:flex-nowrap flex-wrap">
        <form
          ref={form}
          onSubmit={sendEmail}
          className="lg:w-1md:w-1 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
          <h2 className="text-white sm:text-4xl text-3xl mb-1 font-medium title-font">
            Contact Me
          </h2>
          <p className="leading-relaxed mb-5">
            If you would like to contact me to discuss further, please use this form.
          </p>
          <div className="flex flex-wrap -m-4 mb-2">
            <div className="w-full lg:w-1/2 p-4">
              <label htmlFor="name" className="leading-7 text-sm text-gray-400">
                Name
              </label>
              <input
                type="text"
                id="user_name"
                name="user_name"
                className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                onChange={(e) => validateName(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-1/2 p-4">
              <label htmlFor="email" className="leading-7 text-sm text-gray-400">
                Email
              </label>
              <input
                type="email"
                id="user_email"
                name="user_email"
                className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                onChange={(e) => validateEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="relative mb-4 mt-4">
            <label
              htmlFor="message"
              className="leading-7 text-sm text-gray-400">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 h-32 text-base outline-none text-gray-100 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
              onChange={(e) => validateMessage(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
            Submit
          </button>
        </form>
      </div>
    </section>
  );
}